import DataModel from "./Interfaces";
import Mock from "./Mock";
import {API, Auth} from "aws-amplify";


namespace API_BRIDGE {


    export function getFileStructureOfProject(id: string): { purchase: string[]; sales: string[] } {
        return Mock.fileStructure
    }

    export async function getProject(id: string): Promise<DataModel.Project> {
        if (Mock.Projects.filter(p => p.id === id).length !== 1) {
            await getProjectsOverview();
            if (Mock.Projects.filter(p => p.id === id).length !== 1) throw Error("Project with id:" + id + " does not exist!")
        }
        return Mock.Projects.filter(p => p.id === id)[0];
    }

    export async function getProjectsOverview(): Promise<DataModel.Project[]> {
        const user = await Auth.currentUserCredentials()
        const init = { // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            'queryStringParameters': {  // OPTIONAL
                "userId": user.identityId
            }
        };
        const response = await API.get("project", `/project`, init);
        Mock.Projects.length = 0;
        response.data.Items.forEach((i: DataModel.Project) => Mock.Projects.push(i))
        return Mock.Projects;
    }

    export async function createProject(project: DataModel.Project) {
        const init = {
            body: {
                ...project
            },
            headers: {}, // OPTIONAL
        };
        const res = await API.put("project", "/project", init);
        Mock.Projects.push(project);
        console.log(res);
    }

    export async function updateFile(code: {
        filename: string,
        content: string,

    }, projectId: string): Promise<DataModel.Update> {
        const init = {
            body: {
                code: code.content,
                filename: code.filename,
                projectId: projectId
            }, // replace this with attributes you need
            headers: {}, // OPTIONAL
        };
        try {
            console.log(init);
            return await API.post("project", "/update", init);
        } catch {
            return {
                ts: "",
                aspx: "",
                errors: ["Server Error"]
            }
        }
    }

    export async function modifyProject(project: DataModel.Project) {
        const init = {
            body: {}, // replace this with attributes you need
            headers: project, // OPTIONAL
        };
        return await API.post("project", "/project", init);
    }
}
export default API_BRIDGE

