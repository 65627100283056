import {MainRoutes} from "../MainRoutes";

export namespace APUpdateRoutes {


    export enum Routes {
        PROJECT_OVERVIEW = "projects",
        CREATE_NEW = "createNew",
        PROJECT_DETAIL = "projectDetail"
    }

    export const endpointPrefix = MainRoutes.endpointPrefix + MainRoutes.Routes.AP_UPDATE + "/";
    export const endpoint = endpointPrefix + ":page";


}

