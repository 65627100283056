enum TagType {
    OPENING, CLOSING, SELF_CLOSING
}

function GetNextTagIndex(text: string, startIndex: number, currentLine: number) {
    let script = new RegExp("<[\s]*[/]*[\s]*[:\w]*", "sg");
    let subStr = text.substring(startIndex)
    let matches = subStr.match(script);
    if (matches !== null && matches[0] !== null) {
        let beforeMatch = subStr.substring(0, subStr.indexOf(matches[0]));
        return {
            startIndex: startIndex + beforeMatch.length,
            lineNumber: currentLine + beforeMatch.split("\n").length - 1
        }
    } else return null;
}

function GetTagEndIndex(text: string, startIndex: number) {

    return startIndex + text.substr(startIndex).indexOf(">") + 1
}

function GetTagName(tagText: string): string {
    let tagName = ""
    for (let i = 1; i < tagText.length; i++) {
        const char = tagText.charAt(i);
        if (![" ", "/", ">"].includes(char)) {
            tagName += char;
        } else if (tagName !== "") return tagName.toLowerCase()
    }
    throw Error();
}

function GetTagType(tagText: string): TagType {
    let tagName = GetTagName(tagText);
    if (SelfClosing(tagName)) return TagType.SELF_CLOSING;
    for (let i = 1; i < tagText.length; i++) {
        if (tagText.charAt(i) !== " ") {
            if (tagText.charAt(i) === "/") return TagType.CLOSING
            break;
        }
    }
    for (let i = tagText.length - 2; i < tagText.length; i++) {
        if (tagText.charAt(i) !== " ") {
            if (tagText.charAt(i) === "/") return TagType.SELF_CLOSING
            return TagType.OPENING
        }
    }
    throw Error();
}

function SelfClosing(tagName: string) {
    return tagName.match(/doctype|area|base|br|col|embed|hr|img|input|keygen|link|menuitem|meta|param|source|track|wbr|script/i) !== null;
}

function RemoveTextInQuotes(code: string) {
    let result: string = "";
    let quotes = ["'", '"']
    let inLiteral = quotes.includes(code.charAt(0));
    let lastQuote = ""
    if (inLiteral) lastQuote = code.charAt(0)
    for (let i = 0; i < code.length; i++) {
        let char = code.charAt(i);
        if (quotes.includes(char)) {
            if (inLiteral && char === lastQuote) {
                inLiteral = false;
            } else if (!inLiteral) {
                inLiteral = true;
                lastQuote = char;
                result += lastQuote;
            }
        }
        if (!inLiteral || char === "\n") result += char
    }
    return result;
}

function StripASPParts(code: string) {
    let result = "";
    let lastChar = "";
    let inComment = false;
    for (let i = 0; i < code.length; i++) {
        let char = code.charAt(i);
        let dont = false;
        if (!inComment) {
            if (char === "%" && lastChar === "<") {
                result = result.substring(0, result.length - 1)
                inComment = true;
            }
        } else if (lastChar === "%" && char === ">") {
            inComment = false;
            dont = true;
        }
        if (!dont && (!inComment || char === "\n")) result += char;
        if (char !== ' ') lastChar = char;
    }
    return result
}

export function PreprocessCode(code: string) {
    let script = new RegExp("<script(.*?)</script>", "sg");
    let matches = code.match(script);
    if (matches !== null) {
        for (const match of matches) {
            let newLineCount = match.split("\n").length;
            let newLineString = "";
            for (let i = 0; i < newLineCount - 1; i++) {
                newLineString += "\n"
            }
            code = code.replace(match, newLineString)
        }
    }
    code = code.replace(/<!--[\s\S]*?-->/g, "")
    let regExp = new RegExp(
        '<!--[\\s\\S]*?(?:-->)?'
        + '<!---+>?'  // A comment with no body
        + '|<!(?![dD][oO][cC][tT][yY][pP][eE]|\\[CDATA\\[)[^>]*>?'
        + '|<[?][^>]*>?',  // A pseudo-comment
        'g');
    code = code.replace(regExp, "");
    code = RemoveTextInQuotes(code);
    code = StripASPParts(code);

    return code;
}

interface TagInfo {
    type: TagType,
    tagName: string,
    startLineNumber: number
    endLineNumber: number,
    endIndex: number
}


function GetNextTagInfo(code: string, startIndex: number, currentLine: number) {
    let subStr = code.substring(startIndex);
    let startInfo = GetNextTagIndex(code, startIndex, currentLine);
    if (startInfo === null) return null;
    let endIndex = GetTagEndIndex(code, startInfo.startIndex);
    const tagText = code.substring(startInfo.startIndex, endIndex)
    return {
        startLineNumber: startInfo.lineNumber,
        endLineNumber: startInfo.lineNumber + tagText.split("\n").length - 1,
        tagName: GetTagName(tagText),
        type: GetTagType(tagText),
        endIndex: endIndex
    }
}


export function ValidateHTML(code: string) {
    code = PreprocessCode(code)
    const openTags: Array<TagInfo> = new Array<TagInfo>()
    let index = 0;
    const errors = new Array<string>()
    let lineNumber = 1;
    try {
        while (true) {

            const tagInfo = GetNextTagInfo(code, index, lineNumber);
            if (tagInfo === null) break
            index = tagInfo.endIndex;
            lineNumber = tagInfo.endLineNumber;
            if (tagInfo.type === TagType.CLOSING) {
                if (
                    openTags.filter(t => t.tagName === tagInfo.tagName && t.type === TagType.OPENING).length === 0
                ) {
                    errors.push("Der Tag " + tagInfo.tagName + " in Zeile " + tagInfo.startLineNumber + " wurde nie geöffnet");
                    continue;
                }

                while (openTags.length > 0) {
                    const nextOpenTag = openTags.pop();
                    if (nextOpenTag === undefined) {
                        errors.push("Der schließende Tag " + tagInfo.tagName + " in Zeile " + tagInfo.startLineNumber + " wurde nicht geöffnet");
                        break;
                    } else if (nextOpenTag.type !== TagType.OPENING) continue;
                    else if (nextOpenTag.tagName !== tagInfo.tagName) {
                        errors.push("Der öffnende Tag " + nextOpenTag.tagName + " in Zeile " + nextOpenTag.startLineNumber + " wurde nicht richtig geschlossen")
                    } else break;
                }
            } else if (tagInfo.type === TagType.OPENING) openTags.push(tagInfo)
        }
        for (const tag of openTags) {
            errors.push("Der Tag öffnende " + tag.tagName + " in Zeile " + tag.startLineNumber + " wurde nicht geschlossen");
        }
    } catch (Error) {
        errors.push("Die Datei enthält zusätzlich schwerwiegende Fehler im HTML-Markup, die Validierung verhindern. Bitte validieren sie die Datei manuell.")
    }

    return errors;
}


