import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import Sider from "antd/es/layout/Sider";
import "./SideBar.css"
import DataModel from "../../../Data/Interfaces";
import {
    FireOutlined,
    FundProjectionScreenOutlined,
    PlusOutlined,
    ScheduleOutlined,
    SettingOutlined
} from '@ant-design/icons';

const {SubMenu} = Menu;


export function UpdateSideBar(props: { selectedProject: {} }) {
    const project = props.selectedProject as DataModel.Project;
    console.log(project.id !== undefined)
    return (
        <Sider width={200} className="site-layout-background">
            <Menu mode="inline" defaultSelectedKeys={["projects"]} defaultOpenKeys={["sub1"]}>
                <Menu.Item key="projects" icon={<FundProjectionScreenOutlined/>}>
                    <Link to="/apUpdate/projects">Projektübersicht</Link>
                </Menu.Item>
                <Menu.Item key="createNew" icon={<PlusOutlined/>}>
                    <Link to="/apUpdate/projects/create">Neues Projekt erstellen</Link>
                </Menu.Item>
                {
                    project.id !== undefined &&
                    <SubMenu icon={<ScheduleOutlined/>} key="sub1" title={project.name}>
                        <Menu.Item icon={
                            <SettingOutlined/>} key="projectConfig"><Link to={"/apUpdate/project/" + project.id + "/configure"}>Projektkonfiguration</Link></Menu.Item>
                        <Menu.Item icon={
                            <FireOutlined/>} key="projectUpdate"><Link to={"/apUpdate/project/" + project.id + "/update"}>Update</Link></Menu.Item>
                    </SubMenu>
                }
            </Menu>
        </Sider>
    )
}
