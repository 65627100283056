import DataModel from "./Interfaces";

export namespace Mock {
    export const Projects: DataModel.Project[] =
        [];

    export const fileStructure = {
        sales: [
            "auftragRec.aspx", "angebotRec"
        ],
        purchase: [
            "bestellungRec.aspx", "bestellungPosTag.aspx"
        ]
    }


}
export default Mock