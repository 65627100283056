import {Button, Divider, Input, Tabs} from 'antd';
import React, {useEffect, useState} from "react";
import DataModel from "../../../../../Data/Interfaces";
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";

const {TabPane} = Tabs;


const {TextArea} = Input;

const ReadFile = (file: File, setContent: React.Dispatch<React.SetStateAction<string>>) => {
    const reader = new FileReader();
    let text = "";
    reader.onload = (e) => {
        if (reader.result !== null) {
            text = reader.result.toString().trim();
            setContent(text)
        } else throw Error();
    }
    reader.readAsText(file);
}

function handleChange(e: any, i: number,
                      setfileUpdateState: React.Dispatch<React.SetStateAction<DataModel.fileUpdateContent[]>>,) {
    setfileUpdateState(
        state => {
            state[i].content = e;
            state[i].changes = true;
            return state;
        }
    )
}

function getTabName(name: string, i: number, htmlErrors: string[][]) {
    if (htmlErrors.length <= i || htmlErrors[i].length === 0) return name;
    return <span style={{color: "red"}}>
                                {name}
                            </span>
}



export function PrepareASPX(props: {
    fileUpdateState: DataModel.fileUpdateContent[],
    setfileUpdateState: React.Dispatch<React.SetStateAction<DataModel.fileUpdateContent[]>>,
    htmlErrors: string[][],
    validata: any
}) {
    useEffect(() => {
        const readData = async () => {
            let changes: boolean = false;
            console.log(await props.fileUpdateState[0].file.text())
            const newState = props.fileUpdateState.map(
                async s => {
                    if (!s.content)
                        changes = true;
                    return {
                        ...s,
                        content: !s.content ? await s.file.text() : s.content
                    }
                }
            )
            const x = await Promise.all(newState);
            if (changes) {
                props.setfileUpdateState(x);
                props.validata()
            }
        };
        readData();
    }, [props]);
    console.log(props.fileUpdateState)
    const [displayAll, setDisplayAll] = useState(true);
    console.log(displayAll)
    return (
        <Tabs tabPosition="left" style={{height: "100%"}}>
            {
                props.fileUpdateState.
                    map((f, i) => {
                    return {
                        index: i,
                        content: f
                    }
                }).
                filter(
                    f => f.content.content != null &&
                        (displayAll || (props.htmlErrors.length <= f.index || props.htmlErrors[f.index].length != 0))
                        )
                    .map(
                    f => (
                        <TabPane tab={getTabName(f.content.file.name, f.index, props.htmlErrors)} key={f.content.file.name}>
                            <div style={{display: "flex"}}>

                                <AceEditor onChange={(e) => handleChange(e, f.index, props.setfileUpdateState)} mode="html" theme="github" value={f.content.content} name="UNIQUE_ID_OF_DIV" editorProps={{
                                    $blockScrolling: true
                                }} width="1000px" style={{float: "left", flexGrow: 1}}/>
                                <Divider type="vertical"/>
                                <TextArea style={{width: "40%"}} value={props.htmlErrors.length > f.index ? props.htmlErrors[f.index].join("\n") : ""}/>
                            </div>
                            <Button onClick={props.validata}>HTML validieren</Button>
                            <Button onClick={ () => setDisplayAll(!displayAll)}>Fehlerhafte Elemente ein/ausblenden</Button>
                        </TabPane>

                    )
                )
            }
        </Tabs>

    )

}
