import {Input, List, Spin, Tabs} from 'antd';
import DataModel from "../../../../../Data/Interfaces";
import React, {useEffect} from "react";
import API_BRIDGE from "../../../../../Data/API_BRIDGE";
import {LoadingOutlined} from '@ant-design/icons';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/theme-github";

const antIcon = <LoadingOutlined style={{fontSize: 100}} spin/>;

const {TextArea} = Input;
const {TabPane} = Tabs;

const ErrorList = (errors: string[]) =>
    <List size="large" dataSource={errors} renderItem={item => (
        <List.Item style={{color: "red"}}>
            <List.Item.Meta description={<div style={{color: "red"}}>{item.substr(item.indexOf(":") + 1)}</div>}/>
            <div>{item.substring(0, item.indexOf(":"))}</div>
        </List.Item>
    )}/>

const singleFileUpdateView = (data: DataModel.fileUpdateContent) => {
    return (
        <TabPane tab={data.file.name} key={data.file.name}>
            <Tabs defaultActiveKey="errors">
                {data.update?.errors !== undefined && data.update?.errors.length > 0 &&
                <TabPane tab="Fehler und Hinweise" key="errors">
                    {ErrorList(data.update?.errors)}
                </TabPane>
                }
                {data.update?.aspx !== "" &&
                <TabPane tab="ASPX" key="aspx">
                    <AceEditor name={"test"} mode="html" theme="github" value={data.update?.aspx} editorProps={{
                        $blockScrolling: true
                    }} width="50%" showPrintMargin={false}/>
                </TabPane>
                }
                {data.update?.ts !== "" &&
                <TabPane tab="Typescript" key="ts" style={{display: "flex"}}>
                    <AceEditor mode="typescript" theme="github" value={data.update?.ts} editorProps={{
                        $blockScrolling: true
                    }} width="90%" showPrintMargin={false}/>
                </TabPane>
                }
            </Tabs>
        </TabPane>
    )
}

export function FileUpdateView(props: {
    fileUpdateState: DataModel.fileUpdateContent[],
    setfileUpdateState: React.Dispatch<React.SetStateAction<DataModel.fileUpdateContent[]>>,
    projectId: string
}) {

    const [isLoading, setLoading] = React.useState(false);
    let maxLength = 30
    useEffect(() => {
        const fetchData = async () => {
            let changes = false;
            const newState = props.fileUpdateState.map(
                async s => {
                    if (s.changes) {
                        changes = true;
                        if (!isLoading) setLoading(true);
                    }
                    return {
                        ...s,
                        update: s.changes ? await API_BRIDGE.updateFile(
                            {
                                filename: s.file.name,
                                content: s.content
                            }, props.projectId
                        ) : s.update,
                        changes: false
                    }
                }
            )
            if (changes) props.setfileUpdateState(await Promise.all(newState));
            setLoading(false)
        };
        fetchData();
    }, [props]);
    console.log("loading: ", isLoading);
    return (
        <div style={{height: "100%", textAlign: "center"}}>
            {isLoading
                ? <Spin indicator={antIcon} tip="Update wird durchgeführt..."/>
                : <Tabs style={{height: "100%"}} tabPosition="left">
                    {
                        props.fileUpdateState.filter(u => u.update != null).map(
                            f => singleFileUpdateView(f))
                    }
                </Tabs>
            }
        </div>
    )
}
