import {BrowserRouter as Router, Link, Redirect, Route, Switch} from 'react-router-dom'

import {Menu} from 'antd';
import React, {useState} from 'react';
import 'antd/dist/antd.css';
import './App.less';
import {AppConfig} from "../config/AppConfig";
import APUpdateView from "./modules/updateHelper/APUpdateView";
import {Dienstleistungen} from "./modules/dienstleistungen/dienstleistungen";
import {AboutMe} from "./modules/aboutMe/AboutMe";


function createNavbarItem(item: AppConfig.INavigationItem) {
    return (
        <Menu.Item key={item.key}>
            <Link to={"/" + item.url}>{item.text}</Link>
        </Menu.Item>
    )
}

function App() {
    const [key, setKey] = useState("home");
    return (
        <Router>
            <Switch>
                <Route path="/home">
                    {/*<AboutMe/>*/}
                </Route>
                <Route path="/apUpdate">
                    <APUpdateView config={AppConfig.defaultNavbarItems.AP_UPDATE}/>
                </Route>
                <Redirect from={""} to="/home"/>
            </Switch>
        </Router>

    )
}

export default App;




