import React from 'react';
import {Button, Space, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {UploadChangeParam} from "antd/es/upload";
import {UploadFile} from "antd/es/upload/interface";
import DataModel from "../../../../../Data/Interfaces";

const createWrapper = (file: File) => (
    {
        file: file,
        content: "",
        changes: true,
        update: undefined
    }
)

const _handleUpload = (
    setFileUpdateState: React.Dispatch<React.SetStateAction<DataModel.fileUpdateContent[]>>,
    fileList: any,
    setErrors: React.Dispatch<React.SetStateAction<string[][]>>) => {
    const file = fileList.file;
    const test = file.name.split(".");
    const type = test.pop()?.toLowerCase();
    console.log(fileList)
    if (type === "aspx" || type === "js") {
        const x = createWrapper(file);
        setFileUpdateState(filesToUpload => [...filesToUpload, x]);
    }
    setErrors([]);
};


export function SelectFileToUpdate(props: {
    setfileUpdateState: React.Dispatch<React.SetStateAction<DataModel.fileUpdateContent[]>>,
    setErrors: React.Dispatch<React.SetStateAction<string[][]>>
}) {

    const handleUpload = (fileList: UploadChangeParam<UploadFile<any>>) => _handleUpload(props.setfileUpdateState, fileList, props.setErrors);
    return (
        <div style={{height: "100%", textAlign: "center"}}>
            <Space size="large">
                <Upload showUploadList={false} directory beforeUpload={() => false} onChange={handleUpload}>
                    <Button onClick={() => props.setfileUpdateState([])} icon={<UploadOutlined/>}>Ordner
                        auswählen</Button>
                </Upload>
                <Upload showUploadList={false} multiple beforeUpload={() => false} onChange={handleUpload}>
                    <Button onClick={() => props.setfileUpdateState([])} icon={<UploadOutlined/>}>Einzelne Dateien
                        auswählen</Button>
                </Upload>
            </Space>
        </div>
    )
}
