import {Redirect, Route, Switch} from "react-router-dom";
import React, {useState} from "react";
import {Layout} from 'antd';
import {AppConfig} from "../../../config/AppConfig";
import {ProjectOverview} from "./Project/ProjectPreveiw";
import {CreateProject} from "./Project/CreateProject";
import {ProjectDetail} from "./Project/ProjectDetail";
import './SideBar.css';
import DataModel from "../../../Data/Interfaces";
import {UpdateSideBar} from "./updateSideBar";
import "./SideBar.css"
import {UpdateView} from "./Project/Update/UpdateView";
import {withAuthenticator} from '@aws-amplify/ui-react';

const {Content} = Layout;

interface Props {
    config: AppConfig.INavigationItem;
    selectedProject?: DataModel.Project
}

function UpdateHelperView({config}: Props) {
    const [selectedProject, setProject] = useState({});
    return (
        <Layout className="layout" style={{height: "100%"}}>
            <UpdateSideBar selectedProject={selectedProject}/>
            <Content className="site-layout-background" style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                height: "100%"
            }}>
                <Switch>
                    <Route path="/apUpdate/projects/create">
                        <CreateProject/>
                    </Route>
                    <Route path="/apUpdate/project/:id/configure">
                        <ProjectDetail selectedProject={selectedProject}/>
                    </Route>
                    <Route path="/apUpdate/project/:id/update">
                        <UpdateView selectedProject={selectedProject}/>
                    </Route>
                    <Route path="/apUpdate/projects">
                        <ProjectOverview setProject={setProject}/>
                    </Route>
                    <Redirect from={"/apUpdate"} to="/apUpdate/projects"/>
                </Switch>
            </Content>
        </Layout>
    )
}

export default withAuthenticator(UpdateHelperView)
