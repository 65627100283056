export namespace MainRoutes {


    export const endpointPrefix = "/"
    export const endpoint = endpointPrefix + ":page";

    export enum Routes {
        HOME = "home",
        ABOUT_ME = "aboutMe",
        AP_UPDATE = "apUpdate",
    }


}

