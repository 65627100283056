import React, {useState} from 'react';
import {Alert, Button, DatePicker, Divider, Form, Input, InputNumber, Progress, Switch, Upload,} from 'antd';
import {v4 as uuid} from 'uuid';
import {Auth, Storage} from "aws-amplify";
import {CheckCircleTwoTone, UploadOutlined} from '@ant-design/icons';
import {UploadChangeParam} from "antd/es/upload";
import {UploadFile} from "antd/es/upload/interface";
import API_BRIDGE from "../../../../Data/API_BRIDGE";
import {useHistory} from "react-router-dom";

async function UploadProjectFiles(projectId: string, files: File[], setProgress: React.Dispatch<React.SetStateAction<number>>) {
    const todo = new Set<Promise<any>>()
    const names = new Set<string>()
    setProgress(0);
    for (let i = 0; i < files.length; i++) {
        if (todo.size === 10) {
            await Promise.all(todo);
            todo.clear();
            setProgress(Math.round((i / files.length) * 100))
        }
        let name = files[i].name;
        let add = "";
        while (names.has(name)) {
            name = name.substr(add.length)
            if (add === "") add = "0"
            add = (Number(add) + 1).toString()
            name = add + name
        }
        names.add(name)
        const op = Storage.put(projectId + "/" + name, files[i], {
            level: 'private',
            contentType: 'text/plain'
        })
        names.add(files[i].name)
        todo.add(op);
    }
    await Promise.all(todo);
    setProgress(100)
}


const _onFinish = async (filesToUpload: File[], setProgress: React.Dispatch<React.SetStateAction<number>>, values: any) => {
    const id = uuid();
    await UploadProjectFiles(id, filesToUpload, setProgress)
    const user = await Auth.currentUserCredentials();
    await API_BRIDGE.createProject({
        id: id,
        userId: user.identityId,
        name: values.name,
        projectStartDate: values.projectStartDate,
        description: values.description,
        webAPIAccess: values.webAPIAccess,
        numberOfLicences: values.numberOfLicences,
        enabled: false
    });
    console.log("create project finished")
    return id;
};

const _handleUpload = (setFilesToUpload: React.Dispatch<React.SetStateAction<File[]>>, filesToUpload: File[], fileList: any) => {
    const file = fileList.file;
    const type = file.name.split(".").pop().toLowerCase();
    if (type === "aspx" || type === "js") setFilesToUpload(filesToUpload => [...filesToUpload, file]);
};

export const CreateProject = () => {
    const initial: File[] = [];
    const [filesToUpload, setFilesToUpload] = useState(initial);
    const [currentProgress, setProgress] = useState(-1);
    const history = useHistory();
    const onFinish = async (e: any) => {
        if (!e.name) throw Error
        if (filesToUpload.length === 0) throw new Error("Dateien hochladen!")
        const id = await _onFinish(filesToUpload, setProgress, e);
        history.push("/apUpdate/project/" + id + "/configure");
    }


    const handleUpload = (fileList: UploadChangeParam<UploadFile<any>>) => _handleUpload(setFilesToUpload, filesToUpload, fileList)

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <Form labelCol={{span: 4}} wrapperCol={{span: 14}} layout="horizontal" onFinish={onFinish} size="large">
                {
                    filesToUpload.length === 0 &&
                    <div>
                        <Alert message="Information" description="Bitte wählen sie den Ordner des Webservers aus!" type="info" showIcon/>
                        <Divider/>
                    </div>
                }
                <Form.Item label="Projektname" name="name" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Projektbeschreibung" name="projectDescription">
                    <Input/>
                </Form.Item>
                <Form.Item label="Anzahl der Lizenzen" name="numberOfLicences" rules={[{required: true}]}>
                    <InputNumber/>
                </Form.Item>
                <Form.Item label="Projektstart" name="projectStartDate">
                    <DatePicker/>
                </Form.Item>
                <Form.Item label="Webinterface Zugriff" valuePropName="unchecked" name="webApiAccess">
                    <Switch/>
                </Form.Item>
                <Form.Item label="Webserver Code" name="serverCode">
                    <Upload showUploadList={false} directory beforeUpload={() => false} onChange={handleUpload}>
                        {
                            filesToUpload.length === 0 ?
                                <Button icon={<UploadOutlined/>}>Select File</Button>
                                : <CheckCircleTwoTone style={{textAlign: "center"}} twoToneColor="#52c41a"/>
                        }
                    </Upload>
                </Form.Item>
                <Form.Item label="Erstellen">
                    <Button type="primary" htmlType="submit">Projekt erstellen</Button>
                </Form.Item>
            </Form>
            {
                currentProgress >= 0 && <Progress showInfo={false} percent={currentProgress}/>
            }
        </>
    );
};
