import {Card, Col, Row, Spin} from 'antd';
import {BookOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import API_BRIDGE from "../../../../Data/API_BRIDGE";
import DataModel from "../../../../Data/Interfaces";

const {Meta} = Card;
const antIcon = <LoadingOutlined style={{fontSize: 100}} spin/>;

export function CreateNewProjectCard() {
    return (
        <Col className="gutter-row" span={6}>
            <Link to="/apUpdate/projects/create">
                <Card style={{
                    width: 300,
                    height: "100%",
                    borderRadius: 16,
                    overflow: "hidden",
                    backgroundColor: "#C0C0C0"
                }} cover={
                    <PlusOutlined style={{fontSize: '180px'}}/>
                }>
                    <Meta title="Neues Projekt hinzufügen"/>
                </Card>
            </Link>
        </Col>
    )
}

export function ProjectPreview(project: DataModel.BaseProject, setProject: React.Dispatch<React.SetStateAction<{}>>) {
    return (
        <Col className="gutter-row" span={6}>
            <Link to={"/apUpdate/project/" + project.id + "/configure"} onClick={() => setProject(project)}>
                <Card style={{
                    width: 300,
                    height: "100%",
                    borderRadius: 16,
                    overflow: "hidden",
                    backgroundColor: "#C0C0C0"
                }} cover={
                    <BookOutlined style={{fontSize: '180px'}}/>
                }>
                    <Meta title={project.name}/>
                </Card>
            </Link>
        </Col>
    )
}


export function ProjectOverview(props: { setProject: Dispatch<SetStateAction<{}>> }) {
    const initial: DataModel.Project[] = [];
    const [data, setData] = useState(initial);
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const result = await API_BRIDGE.getProjectsOverview()
            setData(result);
            setLoading(false);
        };
        setLoading(true);
        fetchData();
    }, []);
    return (
        <div style={{height: "100%", textAlign: "center"}}>
            {isLoading
                ? <Spin indicator={antIcon}/>
                : <Row justify="space-around" gutter={[0, 100]}>
                    <CreateNewProjectCard/>
                    {data.map(p => ProjectPreview(p, props.setProject))}
                </Row>
            }
        </div>
    )
}

