import {MainRoutes} from "../App/modules/MainRoutes";
import {APUpdateRoutes} from "../App/modules/updateHelper/APUpdateRoutes";


export namespace AppConfig {
    export interface INavigationItem {
        text: string;
        url: string;
        key: string;
        subNavigation: INavigationItem[]
    }


    export const defaultNavbarItems = {
        HOME: {
            text: "Home",
            url: MainRoutes.Routes.HOME,
            key: "home",
            subNavigation: []
        },
        DIENSTLEISTUNGEN: {
            text: "Dienstleistungen",
            url: "dienstleistungen",
            key: "dienstleistungen",
            subNavigation: []
        },
        ABOUT_ME: {
            text: "Über mich",
            url: MainRoutes.Routes.ABOUT_ME,
            key: "about",
            subNavigation: []
        },
        AP_UPDATE: {
            text: "APplus Update Helper",
            url: MainRoutes.Routes.AP_UPDATE,
            key: "applus",
            subNavigation: [
                {
                    text: "Projektübersicht",
                    url: APUpdateRoutes.Routes.PROJECT_OVERVIEW,
                    key: "overview",
                    subNavigation: []
                },
                {
                    text: "Neues Projekt erstellen",
                    url: APUpdateRoutes.Routes.CREATE_NEW,
                    key: "createNewProject",
                    subNavigation: []
                },
                {
                    text: "Projektdetails",
                    url: APUpdateRoutes.Routes.PROJECT_DETAIL,
                    key: "projectDetail",
                    subNavigation: [
                        {
                            text: "Projektkonfiguration",
                            url: "/projects/:id",
                            key: "detail",
                            subNavigation: []
                        },
                        {
                            text: "Projekt-Update",
                            url: "/projects/:id/update",
                            key: "updateProject",
                            subNavigation: []
                        }
                    ]
                }
            ]
        }
    }

    export const defaultItemsArr = [
        defaultNavbarItems.HOME, defaultNavbarItems.AP_UPDATE, defaultNavbarItems.ABOUT_ME, defaultNavbarItems.DIENSTLEISTUNGEN
    ];


}
