/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Project-main",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "apfile111839-main",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "project",
            "endpoint": "https://w1upkhkdie.execute-api.eu-central-1.amazonaws.com/main",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:92d653ae-84c5-4b83-905e-0f263d30ba9d",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_oZjdSQt5K",
    "aws_user_pools_web_client_id": "3dkig0a8odnt5gk2k5dn9vattq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
