import DataModel from "../../../../../Data/Interfaces";
import React, {useEffect} from "react";
import JSZip from 'jszip';
import {saveAs} from 'file-saver';

function makeFile(fileUpdateState: DataModel.fileUpdateContent[]) {
    let zip = new JSZip();
    const scripts = zip.folder("scripts");
    for (const file of fileUpdateState) {
        if (file.update) {
            const fileName = file.file.name.split(".")[0];
            zip.file(fileName + ".aspx", file.update.aspx)
            scripts?.file(fileName + ".ts", file.update.ts)
        }
    }
    return zip
}

export function DownloadView(props: {
    fileUpdateState: DataModel.fileUpdateContent[],
    setfileUpdateState: React.Dispatch<React.SetStateAction<DataModel.fileUpdateContent[]>>
}) {
    useEffect(() => {
        const zip = makeFile(props.fileUpdateState);
        zip.generateAsync({type: "blob"})
            .then(function (blob) {
                saveAs(blob, "converted.zip");
            });
    }, [props.fileUpdateState]);

    return (
        <div>

        </div>
    )
}
