import {Steps} from 'antd';
import {SelectFileToUpdate} from "./SelectFileView";
import React from "react";
import {FileUpdateView} from "./FileUpdateView";
import "./UpdateView.css"
import {PrepareASPX} from "./PrepareASPX";
import DataModel from "../../../../../Data/Interfaces";
import {DownloadView} from "./DownloadView";
import {ValidateHTML} from "../../../../../Tools/HTMLValidator";

const {Step} = Steps;

const ValidateFileHTML = (setErrors: React.Dispatch<React.SetStateAction<string[][]>>, fileUpdateState: DataModel.fileUpdateContent[]) => {
    let errors: string[][] = [];
    for (const fileUpdateContent of fileUpdateState) {
        errors.push(ValidateHTML(fileUpdateContent.content));
    }
    setErrors(errors);
}


export const UpdateView = (props: { selectedProject: {} }) => {
    const project = props.selectedProject as DataModel.Project;

    const [current, setCurrent] = React.useState(0);
    let innn: string[][] = [];
    const [htmlErrors, setErrors] = React.useState(innn);
    const initial: DataModel.fileUpdateContent[] = []
    const [fileUpdateState, setfileUpdateState] = React.useState(initial);
    if (!project.enabled) return <a>Das Projekt wurde noch nicht freigeschaltet!</a>
    const steps = [
        <SelectFileToUpdate setfileUpdateState={setfileUpdateState} setErrors={setErrors}/>,

        <PrepareASPX fileUpdateState={fileUpdateState} setfileUpdateState={setfileUpdateState} htmlErrors={htmlErrors} validata={() => ValidateFileHTML(setErrors, fileUpdateState)}/>

        ,

        <FileUpdateView projectId={project.id} fileUpdateState={fileUpdateState} setfileUpdateState={setfileUpdateState}/>,
        <DownloadView fileUpdateState={fileUpdateState} setfileUpdateState={setfileUpdateState}/>,
    ];

    return (
        <div style={{height: "100%", display: "flex", flexFlow: "column"}}>
            <div style={{flex: "0 1 auto"}}>
                <Steps current={current} type="navigation" className="site-navigation-steps">

                    <Step key="Datei auswählen" title="Datei auswählen" onClick={() => setCurrent(0)}/>
                    {fileUpdateState.length > 0 &&
                    <Step key="prepareASPX" title="ASPX vorbereiten" onClick={() => setCurrent(1)}/>}
                    {fileUpdateState.length > 0 && htmlErrors.length > 0 &&
                    <Step key="Update" title="Update" onClick={() => setCurrent(2)}/>}
                    {fileUpdateState.length > 0 && htmlErrors.length > 0 &&
                    <Step key="Download" title="Donwload" onClick={() => setCurrent(3)}/>}
                </Steps></div>

            <div style={{flex: "1 1 auto", display: "flex", flexFlow: "column", overflowY: "auto"}}>
                {steps[current]}
            </div>

            {/*<FileUpdateView/>*/}
        </div>
    );
};
