import {DatePicker, Form, Input, InputNumber, Switch} from "antd";
import moment from "moment";
import React from "react";
import DataModel from "../../../../Data/Interfaces";

export function ProjectDetail(props: { selectedProject: {} }) {
    const project = props.selectedProject as DataModel.Project;
    const apiAccess: string = project.webAPIAccess ? "checked" : "unchecked";
    console.log(project);
    return (
        <Form labelCol={{span: 4}} wrapperCol={{span: 14}} layout="horizontal" size="large">
            <Form.Item label="Projektname" name="name">
                <Input defaultValue={project.name}/>
            </Form.Item>
            <Form.Item label="Projektbeschreibung" name="projectDescription">
                <Input defaultValue={project.description}/>
            </Form.Item>
            <Form.Item label="Anzahl der Lizenzen" name="numberOfLicences">
                <InputNumber defaultValue={project.numberOfLicences}/>
            </Form.Item>
            <Form.Item label="Projektstart" name="projectStartDate">
                <DatePicker defaultValue={moment(project.projectStartDate)} format="DD.MM.YYYY"/>
            </Form.Item>
            <Form.Item label="Webinterface Zugriff" valuePropName={apiAccess} name="webApiAccess">
                <Switch/>
            </Form.Item>
        </Form>
    )
}